

















































































































































































































































































































































































































































































































































































































































































































































































































































































































import Base from '@/mixins/Base.vue';
import {
    IJob, IJobResponse,
} from '@/interfaces/job';
import { ISpObject } from '@/interfaces/spobject';
import { IService } from '@/interfaces/service';
import { IClient, IClientResponse } from '@/interfaces/client';
import { ITag } from '@/interfaces/tag';
import { IExtraField } from '@/interfaces/extras';
import { IServiceCategory } from '@/interfaces/servicecategory';
import { IBuildingType } from '@/interfaces/buildingtype';
import { ITask } from '@/interfaces/task';
import { IBilling } from '@/interfaces/billing';
import { IProjectResponse } from '@/interfaces/project';

const component = Base.extend({
    props: {
        clientId: {
            type: String,
            default: undefined,
        },
        type: {
            type: String,
            default: 'card',
        },
    },
    data() {
        return {
            params: {
                name: '',
                category: undefined as (any | IServiceCategory),
                tags: [] as Array<ITag>,
                buildingTypes: undefined as (any | IBuildingType),
            },
            active: true,
            searchService: undefined as (any | IService),
            subserviceModalIndex: false,
            isEditTaskModalActive: false,
            isAddQrCodeModalActive: false,
            isCreateProjectModalActive: false,
            editObjectId: false,
            tags: [] as Array<ITag>,
            buildingTypes: [] as Array<IBuildingType>,
            services: [] as Array<IService>,
            selectedObjectIndex: undefined,
            selectedTask: undefined as (any | ITask),
            newObject: {} as ISpObject,
            newProject: {
                name: '',
            },
            job: {
                tasks: [] as Array<ITask>,
                client: undefined as any | IClient,
            } as IJob,
            selectedTags: [] as Array<ITag>,
            jobBillings: [] as Array<IBilling>,
        };
    },

    mounted() {
        if (this.currentUser && this.currentUser.current_client && this.currentUser.current_client_id) {
            this.getClientById(this.currentUser.current_client_id);
        }

        let client_id: string = this.clientId;
        if (this.$route) {
            client_id = this.$route.params.client_id;
        }

        if (client_id) {
            this.getClientById(parseInt(client_id, 0));
        }
    },
    computed: {
        filteredServices() {
            if (this.services && this.services) {
                let data: Array<IService> = this.services.filter((p: IService) => !p.parent_id);

                if (this.params.tags.length) {
                    data = data.filter((service: IService) => {
                        let contains: boolean = false;
                        this.params.tags.forEach((tag: ITag) => {
                            if (service.tags.find((ptag: ITag) => ptag.id === tag.id)) {
                                contains = true;
                            }
                        });

                        return contains;
                    });
                }

                if (this.params.name) {
                    data = data.filter((service: IService) => service.name.toLowerCase()
                        .includes(this.params.name.toLowerCase()));
                }

                if (this.params.category) {
                    data = data.filter((service: IService) => service.category_id === this.params.category.id);
                }

                return data;
            }
            return [];
        },
    },
    methods: {
        scrollTo(id: string) {
            setTimeout(() => {
                const ref = this.$refs[id] as HTMLElement;
                if (ref) {
                    ref.scrollIntoView({
                        behavior: 'smooth',
                    });
                }
            }, 100);
        },
        removeObjectFromTask(item: any, index: number): void {
            this.$delete(item.objects, index);
        },

        createObjectForTask(task: ITask): void {
            const newObject: ISpObject = {
                name: 'New object',
                client_reference_1: '',
                client_reference_2: '',
                description: '',
                serial: '',

            } as ISpObject;

            task.objects.push(newObject);
            this.$forceUpdate();
        },
        getClientById(id: number): void {
            this.tags = [];
            this.get<IClientResponse>(`clients/${id}`)
                .then(({ data }) => {
                    this.job.client = data.data;

                    this.getTags();
                });
        },
        getBuildingTypes(): void {
            if (this.job.client && this.job.client.id) {
                this.get('building-types')
                    .then(({ data }) => {
                        this.buildingTypes = data.data;
                    });
            } else {
                this.tags = [];
            }
        },
        getTags(): void {
            if (this.job.client && this.job.client.id) {
                this.get(`clients/${this.job.client.id}/tags`)
                    .then(({ data }) => {
                        this.tags = data.data;
                    });
            } else {
                this.tags = [];
            }
        },
        removeTask(task: ITask): void {
            this.confirm('Remove Task').then(() => {
                const index: number = this.job.tasks.findIndex((t) => task === t);
                this.$delete(this.job.tasks, index);
            });
        },
        saveProject() {
            this.post<IProjectResponse>('projects', {
                name: this.newProject.name,
                client_id: this.job.client.id,
            }).then(({ data }) => {
                this.job.project = data.data;
                this.job.project_id = data.data.id;
            }).finally(() => {
                this.isCreateProjectModalActive = false;
            });
        },
        onInvoiceTo() {
            if (this.selectedTask.service) {
                this.selectedTask.billings = [];
                if (this.selectedTask.service.splitting_behavior === 'always' || (this.selectedTask.service.splitting_behavior === 'allow' && this.selectedTask.invoice_to === '*')) {
                    this.selectedTask.service.billings.forEach((billing: IBilling, b: number) => {
                        this.selectedTask.billings[b] = {
                            type: billing.type,
                            name: billing.type === 'client' ? this.job.client.name : '',
                            email: billing.type === 'client' ? this.job.client.email : '',
                            address: billing.type === 'client' ? this.job.client.billing_address : undefined,
                            address_id: billing.type === 'client' ? this.job.client.billing_address_id : undefined,
                        };
                    });
                }

                if (this.selectedTask.service.splitting_behavior === 'never' || (this.selectedTask.service.splitting_behavior === 'allow' && this.selectedTask.invoice_to !== '*')) {
                    const billing = this.selectedTask.service.billings.find((b:IBilling) => b.type === this.selectedTask.invoice_to);

                    if (billing) {
                        this.selectedTask.billings = [{
                            type: this.selectedTask.invoice_to,
                            name: billing.type === 'client' ? this.job.client.name : '',
                            email: billing.type === 'client' ? this.job.client.email : '',
                            address: billing.type === 'client' ? this.job.client.billing_address : undefined,
                            address_id: billing.type === 'client' ? this.job.client.billing_address_id : undefined,
                        }];
                    }
                }
            }
        },
        onService(service: IService) {
            if (service) {
                this.get(`services/${service.id}`, {
                    params: {
                        with_source: true,
                        append_children: true,
                    },
                }).then(({ data }) => {
                    this.selectedTask.service = data.data;
                    this.selectedTask.invoice_to = undefined;
                    this.selectedTask.objects = [];
                    this.selectedTask.billings = [];
                    this.selectedTask.extras_fields = [];

                    if (service.has_pickup_key_address) {
                        this.selectedTask.pickup_key_address_id = this.job.client.address_id;
                        this.selectedTask.pickup_key_address = this.job.client.address;
                    }

                    if (service.children && service.children.length) {
                        this.selectedTask.tasks = service.children.map((child: IService) => ({
                            service: child,
                            service_id: child.id,
                            objects: [],
                            inspection_address: this.job.client.address,
                            inspection_address_id: this.job.client.id,
                            extras_fields: [],
                            pickup_key_address_id: child.has_pickup_key_address ? this.job.client.address_id : null,
                            pickup_key_address: child.has_pickup_key_address ? this.job.client.address : undefined,
                            billings: [],
                        } as ITask));
                    }

                    this.selectedTask.invoice_to = this.selectedTask.service?.billings[0].type || '';

                    this.onInvoiceTo();
                });
            }
        },
        createTask() {
            this.selectedTags = [];

            this.selectedTask = {
                service: undefined as any | IService,
                service_id: undefined,
                objects: [],
                inspection_address: this.job.client.address,
                inspection_address_id: this.job.client.id,
                extras_fields: [],
                pickup_key_address_id: undefined,
                pickup_key_address: undefined,
                billings: [] as Array<IBilling>,
                tasks: [] as Array<ITask>,
            } as ITask;

            this.isEditTaskModalActive = true;
        },
        addTask() {
            this.isEditTaskModalActive = false;
            this.job.tasks.push(JSON.parse(JSON.stringify(this.selectedTask)));
            this.selectedTask = undefined;
        },
        duplicateTask(task: ITask): void {
            this.job.tasks.push(JSON.parse(JSON.stringify(task)));
        },
        editTask(task: ITask): void {
            this.selectedTask = task;
            this.isEditTaskModalActive = true;
        },
        saveJob(): void {
            this.post<IJobResponse>('jobs', {
                ...this.job,
                billings: this.jobBillings.map((billing: IBilling) => ({
                    type: billing.type,
                    name: billing.name,
                    email: billing.email,
                    address: billing.address,
                    vat: billing.vat,
                })),
                client_id: this.job.client.id,
                tasks: this.job.tasks.map((task: ITask) => ({
                    service_id: task.service_id,
                    billing_type: task.billing_type,
                    objects: task.objects.map((object: ISpObject) => ({
                        id: object.id,
                        name: object.name,
                        client_reference_1: object.client_reference_1,
                        client_reference_2: object.client_reference_2,
                    })),
                    extras_fields: task.extras_fields.map((extra: IExtraField) => ({
                        id: extra.id,
                        value: extra.value,
                    })),
                })),

            })
                .then(() => {
                    this.$emit('success', {
                        message: 'Job created',
                        animation: true,
                    });

                    this.$router.push({ name: 'jobs.list' });
                });
        },
    },
});

export default component;
